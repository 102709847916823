<div class="filter-sort-container d-flex align-items-center" data-id="Detailed View Filter"
  [class]="isFiltering() ? 'active' : 'deactive'">
  <button mat-icon-button [matMenuTriggerFor]="menu" data-id="filterIcon" #trigger="matMenuTrigger" [class.menu-opened]="trigger.menuOpen">
    <mat-icon fontSet="al" [fontIcon]="isFiltering() ? 'al-ico-filter-on' : 'al-ico-filter-off'">
      <span class="path1"></span><span class="path2"></span>
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="table-card-filter-menu">
    @for (option of optionsFiltered(); track option.key) {
      @let col = getColumnByOption(option);
      @if (col) {
        <button mat-menu-item [matMenuTriggerFor]="subMenu" (menuOpened)="menuOpened.emit(col)">
          <mat-icon fontSet="al" fontIcon="al-ico-completed" class="sm"
                    [style.visibility]="this.value()[option.key]?.length > 0 ? 'visible' : 'hidden'"
          ></mat-icon>
          {{col?.header}}
        </button>
        <mat-menu #subMenu="matMenu" class="table-card-filter-menu" (closed)="closeMenu(col)">
          @if (col?.searchableFilter) {
            <form class="filter-form">
              <mat-form-field appearance="fill" smClickStopPropagation class="tags-menu-input w-100 no-bottom">
                <input
                  matInput
                  [(ngModel)]="searchTerms[option.key]"
                  (input)="container.scrollTo({top: 0}); setSearchTerm($event, option.key)"
                  name="filter"
                  autocomplete="off"
                >
                @if (searchTerms[option.key]) {
                  <button mat-icon-button matSuffix class="me-1" (click)="clearSearch(option.key)">
                    <mat-icon fontSet="al" fontIcon="al-ico-dialog-x"></mat-icon>
                  </button>
                } @else {
                  <mat-icon matSuffix fontSet="al" fontIcon="al-ico-search" class="me-1"></mat-icon>
                }
              </mat-form-field>
            </form>
          }
          @if (col?.andFilter) {
            <div class="filter-type" smClickStopPropagation>
              <button mat-button data-id="AnyTagOption" class="link"
                      [class.selected]="filterMatch()[option.key] !== 'AND'"
                      (click)="toggleCombination(option.key)"
              >Any</button>
              <button mat-button data-id="allTagOption" class="link"
                      [class.selected]="filterMatch()[option.key] === 'AND'"
                      (click)="toggleCombination(option.key)"
              >All</button>
            </div>
          }
          <div #container class="results" [class.scroll]="col.searchableFilter" smClickStopPropagation>
            <sm-checkbox-three-state-list
              [supportExcludeFilter]="col.excludeFilter"
              [checkedList]="value()[col.id]"
              [options]="col.paginatedFilterPageSize ?
                 (option.value | filter : searchTerms[option.key]: 'label')?.slice(0, col.paginatedFilterPageSize * pageNumber()) ?? [] :
                 (option.value | filter : searchTerms[option.key]: 'label')"
              (filterChanged)="emitFilterChangedCheckBox(option.key, $event)"
            ></sm-checkbox-three-state-list>
            @if (option.value !== null && col.paginatedFilterPageSize &&
            col.paginatedFilterPageSize * pageNumber() <= (option.value | filter : searchTerms[option.key]: 'label')?.length) {
              <sm-dots-load-more
                [loading]="loading()"
                (loadMore)="loadMore()"
                class="mx-auto my-2"
              ></sm-dots-load-more>
            }
          </div>
          @if (option.key==='tags') {
            <div class="fixed-options">
              <mat-divider></mat-divider>
              @if (subOptions()?.length) {
                <div class="fixed-options-subheader">
                  {{fixedOptionsSubheader()}}
                </div>
              }
              @for (option of subOptions(); track option.label) {
                <sm-menu-item
                  [itemLabel]="option.label"
                  [checked]="subValue()?.includes(option.value)" [selectable]="true" [itemValue]="option.value"
                  (itemClicked)="onSubFilterChanged({id :'system_tags'}, $event)"
                ></sm-menu-item>
              }
            </div>
          }
          @if (col.columnExplain && optionsFiltered().length) {
            <div class="column-explanation">{{col.columnExplain}}</div>
          }
        </mat-menu>
      }
    }
    <hr>
      <div mat-menu-item class="clear-all mat-menu-item" (click)="isFiltering() && clearAll.emit()" [disabled]="!isFiltering()">Clear all</div>
    </mat-menu>
  </div>

