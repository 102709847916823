import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  signal, input, output, computed
} from '@angular/core';
import {ScalarKeyEnum} from '~/business-logic/model/events/scalarKeyEnum';
import {MatSelectChange} from '@angular/material/select';
import {GroupByCharts} from '@common/experiments/actions/common-experiment-output.actions';
import {SmoothTypeEnum, smoothTypeEnum} from '@common/shared/single-graph/single-graph.utils';

@Component({
  selector: 'sm-graph-settings-bar',
  templateUrl: './graph-settings-bar.component.html',
  styleUrls: ['./graph-settings-bar.component.scss']
})
export class GraphSettingsBarComponent {
  private readonly shortModeWidth = 1200;
  readonly scalarKeyEnum = ScalarKeyEnum;
  readonly round = Math.round;
  protected readonly smoothTypeEnum = smoothTypeEnum;
  private el = inject(ElementRef);

  public shortMode = signal(this.el.nativeElement.clientWidth < this.shortModeWidth);

  @Input() set splitSize(splitSize: number) {
    this.shortMode.set(this.el.nativeElement.clientWidth < this.shortModeWidth);
  }

  smoothWeight = input<number>();
  smoothType = input<SmoothTypeEnum>();
  xAxisType = input<ScalarKeyEnum>(ScalarKeyEnum.Iter);
  groupBy = input<GroupByCharts>('metric');
  groupByOptions = input<{
        name: string;
        value: GroupByCharts;
    }[]>();
  verticalLayout = input<boolean>(false);
  changeWeight = output<number>();
  changeSmoothType = output<SmoothTypeEnum>();
  changeXAxisType = output<ScalarKeyEnum>();
  changeGroupBy = output<GroupByCharts>();
  toggleSettings = output();

  smoothState = computed(() => ({
    smoothWeight: this.smoothWeight(),
    weight: signal(this.smoothWeight())
  }));

  @HostListener('window:resize')
  onResize() {
    this.shortMode.set(this.el.nativeElement.clientWidth < this.shortModeWidth);
  }

  xAxisTypeOption = [
    {
      name: 'Iterations',
      value: ScalarKeyEnum.Iter
    },
    {
      name: 'Time from start',
      value: ScalarKeyEnum.Timestamp
    },
    {
      name: 'Wall time',
      value: ScalarKeyEnum.IsoTime
    },
  ];

  constructor() {
    window.setTimeout(() => {
      this.shortMode.set(this.el.nativeElement.clientWidth < this.shortModeWidth);
    }, 100);
  }
  xAxisTypeChanged(key: MatSelectChange) {
    this.changeXAxisType.emit(key.value);
  }

  groupByChanged(key: MatSelectChange) {
    this.changeGroupBy.emit(key.value);
  }

  selectSmoothType($event: MatSelectChange) {
    this.changeWeight.emit([smoothTypeEnum.exponential, smoothTypeEnum.any].includes($event.value) ? 0 : 10);
    this.changeSmoothType.emit($event.value);
  }

  trimToLimits(value: number) {
    if (value === 0) {
      return;
    }
    if (value === null) {
      this.changeWeight.emit(this.smoothState().weight());
      return;
    }
    let smoothWeight = this.smoothState().weight();
    if (value > (this.smoothType() === smoothTypeEnum.exponential ? 0.999 : 100) || value < (this.smoothType() === smoothTypeEnum.exponential ? 0 : 1)) {
      smoothWeight = null;
    }
    setTimeout(() => {
      if (this.smoothType() === smoothTypeEnum.exponential) {
        if (value > 0.999) {
          smoothWeight = 0.999;
        } else if (value < 0) {
          smoothWeight = 0;
        }
      } else {
        if (value > 100) {
          smoothWeight = 100;
        } else if (value < 1) {
          smoothWeight = 1;
        }
      }
      this.smoothState().weight.set(smoothWeight);
      this.changeWeight.emit(smoothWeight);
    });
  }
}
